<template>
  <b-row class="mainDiv">
    <b-row>
      <b-row class="d-flex justify-content-between">
        <b-col class="LastTenOrd p-0 mb-1">
          <h4 class="p-1 m-0 text-center" style="color: #2d838e">
            {{ $t("last 10 orders") }}
          </h4>
        </b-col>
        <b-col class="InvoiceGraphLine p-0 mb-1">
          <h4 class="p-1 m-0 text-center" style="color: #2d838e">
            {{ $t("Invoiced") }}
          </h4>
        </b-col>
      </b-row>
      <b-row class="GraphAndTableSection d-flex justify-content-between">
        <b-col id="TableData" class="p-0">
          <b-table
            sticky-header
            ref="refInvoiceListTable"
            responsive
            :fields="tableColumns"
            primary-key="id"
            show-empty
            empty-text="No matching records found"
            class="position-relative mb-0 p-0"
            tbody-tr-class="rowClass"
            :items="this.orderdata"
          >
          </b-table>
        </b-col>
        <b-row id="GraphData" class="p-0">
          <b-row class="d-flex" id="SelYears">
            <b-col class="col-md-3">
              <p class="mt-0 mb-0" id="SelYearsTitle">
                {{ $t("Select the Years") }}
              </p>
            </b-col>
            <b-col class="InputBox">
              <input
                type="checkbox"
                v-model="DATA_y"
                value="2023"
                class="check-btn Years_wise_graph"
                id="Year_2023"
              />
              <label
                for="Year_2023"
                class="Y_W_g"
                style="background-color:#FF0000;color:white;"
                >2023</label
              >
              <input
                type="checkbox"
                v-model="DATA_y"
                value="2022"
                class="check-btn Years_wise_graph"
                id="Year_2022"
              />
              <label
                for="Year_2022"
                class="Y_W_g"
                style="background-color:#FFA500;color:white;"
                >2022</label
              >
              <input
                type="checkbox"
                v-model="DATA_y"
                value="2021"
                class="check-btn Years_wise_graph"
                id="Year_2021"
              />
              <label
                for="Year_2021"
                class="Y_W_g"
                style="background-color:#FFFF00;color:black;"
                >2021</label
              >
              <input
                type="checkbox"
                v-model="DATA_y"
                value="2020"
                class="check-btn Years_wise_graph"
                id="Year_2020"
              />
              <label
                for="Year_2020"
                class="Y_W_g"
                style="background-color:#00FF00;color:black;"
                >2020</label
              >
              <input
                type="checkbox"
                v-model="DATA_y"
                value="2019"
                class="check-btn Years_wise_graph"
                id="Year_2019"
              />
              <label
                for="Year_2019"
                class="Y_W_g"
                style="background-color:#0000FF;color:white;"
                >2019</label
              >
            </b-col>
          </b-row>
          <b-col class="p-0">
            <div id="chart" class="p-0">
              <apexchart
                type="bar"
                height="250"
                :options="chartOptions"
                :series="YearGraphData"
                class="p-0"
              ></apexchart>
            </div>
          </b-col>
        </b-row>
      </b-row>
    </b-row>
    <b-row>
      <b-row class="d-flex justify-content-between">
        <b-col class="DashBoardMonth p-0 mb-1 mt-3">
          <h4 class="p-1 m-0 text-center" style="color: #2d838e">
            {{ $t("daily orders") }}
          </h4>
        </b-col>
        <b-col class="PiGraphLineText p-0 mb-1 mt-3">
          <h4 class="p-1 m-0 text-center" style="color: #2d838e">
            {{ $t("Shop orders") }}
          </h4>
        </b-col>
      </b-row>
      <b-row class="d-flex justify-content-between">
        <b-col class="PIGraphsec1">
          <b-row class="YearsWithMonth">
            <b-row class="d-flex ">
              <b-col class="text-center mx-1 col-3 mt-1 ">
                <h5 style="color:#000; font-weight:800">{{ $t("Years") }}</h5>
              </b-col>
              <b-col class="text-center col-3  mt-1">
                <h5 style="color:#000; font-weight:800">{{ $t("month") }}</h5>
              </b-col>
            </b-row>
            <b-col class="p-0 col-md-12 col-xl-12 YearsWithMonthsec">
              <b-col class="col-3 mr-2 ml-0 p-0">
                <v-select
                  v-model="PiYearsFIlter"
                  dir="ltr"
                  :options="PiYearsFiData"
                  :clearable="false"
                  class="invoice-filter-select p-0"
                  :placeholder="$t('years')"
                  style="background-color:transparent;"
                >
                </v-select>
              </b-col>
              <b-col class="col-3 p-0">
                <v-select
                  v-model="PiMonthFIlter"
                  dir="ltr"
                  :options="PiMonthFiData"
                  :clearable="false"
                  class="invoice-filter-select p-0"
                  :placeholder="$t('years')"
                  style="background-color:transparent;"
                >
                </v-select>
              </b-col>
            </b-col>
          </b-row>
          <div id="chart">
            <apexchart
              type="bar"
              ref="Mychartyear"
              height="250"
              :key="MonAndYerData.length"
              :options="chartOptionsMonYer"
              :series="MonAndYerData"
            ></apexchart>
          </div>
        </b-col>
        <b-col class="PIGraphsec2 col-md-6">
          <b-row class="">
            <b-col class="p-0 col-md-12 col-xl-12 mt-1 piUnderTitle">
              <h4 class="m-0 mb-1">{{ $t("last ....... days") }}</h4>
            </b-col>
            <b-col class="p-0 piUnderSelectBox">
              <v-select
                v-model="FilterPiDays"
                dir="ltr"
                :options="daysOptions"
                :clearable="false"
                class="invoice-filter-select-per mb-3 p-0 col-md-12"
                :placeholder="$t('years')"
              >
              </v-select>
            </b-col>
          </b-row>
          <div id="chart">
            <apexchart
              ref="myChart"
              type="donut"
              :key="PiGraphData.length"
              width="350"
              :options="RoundGraph"
              :series="PiGraphData"
            ></apexchart>
          </div>
        </b-col>
      </b-row>
    </b-row>
  </b-row>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import i18n from "@/libs/i18n/index";
import {
  BCard,
  BCardText,
  BLink,
  BCol,
  BFormRadio,
  BFormCheckbox,
  BTable,
} from "bootstrap-vue";
import axios from "axios";
import vSelect from "vue-select";
export default {
  props: {},
  components: {
    BCard,
    BCardText,
    BLink,
    BCol,
    vSelect,
    apexchart: VueApexCharts,
    BFormRadio,
    BFormCheckbox,
    BTable,
  },
  data() {
    return {
      tableColumns: [
        { key: "orderNumber", label: i18n.t("orderNumber"), sortable: true },
        { key: "shop", label: i18n.t("shop"), sortable: true },
        { key: "customer", label: i18n.t("customer"), sortable: true },
        { key: "date", label: i18n.t("date"), sortable: true },
      ],
      orderdata: [],
      InvoicePaymentData: [],
      PiDataGet: [],
      FilterPiDays: 30,
      PiYearsFIlter: new Date().getFullYear(),
      PiMonthFIlter: new Date().getMonth() + 1,
      PiYearsFiData: ["2023", "2022", "2021", "2020", "2019"],
      PiMonthFiData: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      daysOptions: ["1", "7", "14", "30", "365"],
      YearsData: ["2022", "2021", "2020", "2019", "2018"],
      items: [
        {
          age: 40,
          first_name: "Dickerson",
          last_name: "Macdonald",
        },
        {
          age: 21,
          first_name: "Larsen",
          last_name: "Shaw",
        },
        {
          age: 89,
          first_name: "Geneva",
          last_name: "Wilson",
        },
        {
          age: 38,
          first_name: "Jami",
          last_name: "Carney",
        },
      ],
      DATA_y: ["2023", "2022"],
      TotalInAmont: 0,
      PiGraphData: [],
      RoundGraph: {
        name: "Year 2022",
        chart: {
          width: 350,
          type: "donut",
        },
        labels: [],
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
          },
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: "gradient",
        },
        legend: {
          formatter: function(val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex];
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                show: false,
                position: "bottom",
              },
            },
          },
        ],
      },
      YearGraphData: [
        {
          name: "Year 2023",
          data: [],
          id: "2023",
        },
        {
          name: "Year 2022",
          data: [],
          id: "2022",
        },
        {
          name: "Year 2021",
          data: [],
          id: "2021",
        },
        {
          name: "Year 2020",
          data: [],
          id: "2020",
        },
        {
          name: "Year 2019",
          data: [],
          id: "2019",
        },
      ],
      chartOptions: {
        colors: ["#FF0000", "#FFA500", "#FFFF00", "#00FF00", "#0000FF"],
        chart: {
          type: "bar",
          height: 350,
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        yaxis: {
          title: {
            // text: '$ (thousands)'
          },
          labels: {
            formatter: function(value) {
              return value + "k";
            },
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          download: false,
          y: {
            formatter: function(val) {
              return val;
            },
          },
        },
      },
      MonAndYerData: [
        {
          name: "OrderCount",
          data: [],
        },
      ],
      chartOptionsMonYer: {
        colors: ["#005f74"],
        chart: {
          height: 250,
          type: "bar",
          toolbar: {
            show: false,
            tools: {
              download: false,
            },
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function(val) {
            return val;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function(val) {
              return val;
            },
          },
        },
        title: {
          text: "Monthly Inflation in Argentina, 2002",
          floating: true,
          offsetY: 330,
          align: "center",
          style: {
            color: "#444",
          },
        },
      },
    };
  },
  watch: {
    FilterPiDays: function(val, oldval) {
      this.FilterPiDays = val;
      this.DayData();
    },
    PiYearsFIlter: function(val) {
      this.PiYearsFIlter = val;
      this.yearMonthDataGet();
    },
    PiMonthFIlter: function(val) {
      this.PiMonthFIlter = val;
      this.yearMonthDataGet();
    },
    DATA_y: function(val) {
      this.DATA_y = val;
      this.InvoiceData();
    },
  },
  created() {
    localStorage.setItem("title", "Dashboard");
    this.mytoken = localStorage.getItem("token");
    console.log("token", this.mytoken);
    if (this.mytoken == "") {
      localStorage.removeItem("token");
      this.$router.push({
        name: "login",
      });
    } else {
      var config = {
        method: "get",
        url:
          "https://engine.netsupport.dk:8270/login/v1/checktoken/" +
          this.mytoken,
      };
      axios(config)
        .then((response) => {
          if (response.data.token.status == true) {
            this.FilterPiDays = localStorage.getItem("Pidays");
            this.PiMonthFIlter = localStorage.getItem("Pimofil");
            this.PiYearsFIlter = localStorage.getItem("Piyearfil");
            if (
              this.FilterPiDays == null &&
              this.PiMonthFIlter == null &&
              this.PiYearsFIlter == null
            ) {
              this.FilterPiDays = 30;
              this.PiMonthFIlter = 1;
              this.PiYearsFIlter = 2022;
            }
          }
        })
        .catch(function(error) {
          console.log(error);
          if (error.response.status == 404) {
            localStorage.setItem("token", "");
            localStorage.removeItem("username");
            localStorage.removeItem("days");
            localStorage.removeItem("pagesize");
            window.location.href = "/login";
          }
        });
    }
    this.InvoiceData();
    this.DayData();
    this.OrderDataGet();
    this.yearMonthDataGet();
  },
  methods: {
    OrderDataGet() {
      var config = {
        method: "get",
        url:
          "https://engine.netsupport.dk:8270/dashboard/v1/last10orders/" +
          this.mytoken,
      };
      axios(config)
        .then((res) => {
          this.orderdata = JSON.parse(JSON.stringify(res.data.shops.recordset));
          // console.log("this.orderdata", this.orderdata);
        })
        .catch(function(error) {
          console.log(error);
        });
    },

    yearMonthDataGet() {
      localStorage.setItem("Pimofil", this.PiMonthFIlter);
      localStorage.setItem("Piyearfil", this.PiYearsFIlter);
      var config = {
        method: "get",
        url:
          "https://engine.netsupport.dk:8270/dashboard/v1/31days/" +
          this.mytoken +
          "?month=" +
          this.PiMonthFIlter +
          "&year=" +
          this.PiYearsFIlter,
      };
      axios(config)
        .then((res) => {
          // console.log(res , "res ====>>>>>");
          let PiDataStore = res.data.days;
          // console.log("PiDataStore ==>>>>",PiDataStore);
          var uniq = [];
          PiDataStore.map((ele) => uniq.push(ele));
          const ref = JSON.parse(JSON.stringify(PiDataStore));
          // console.log("uniq",uniq);
          let abc = PiDataStore.map((a) => a);
          this.MonAndYerData = [{ ...this.MonAndYerData, data: abc }];

          // console.log('this.MonAndYerData',this.MonAndYerData[0].data.length);
          let array = [];
          for (let index = 0; index < 31; index++) {
            array.push(index + 1);
          }

          this.chartOptionsMonYer = {
            ...this.chartOptionsMonYer,
            xaxis: { categories: array },
          };
          // let def = ref.map((a) => new Date(a.dayOfMonth).toLocaleDateString("en-US", {weekday: 'short'}));
          // this.chartOptionsMonYer = {
          //   ...this.chartOptionsMonYer,
          //   xaxis: {categories: def},
          // };
          // console.log("this.MonAndYerData",def);
        })
        .catch(function(error) {
          console.log(error);
        });
      this.$refs.Mychartyear.updateSeries(this.MonAndYerData);
      // window.dispatchEvent(new Event("resize"));
    },
    async DayData() {
      localStorage.setItem("Pidays", this.FilterPiDays);
      var config = {
        method: "get",
        url:
          "https://engine.netsupport.dk:8270/dashboard/v1/shoporders/" +
          this.mytoken +
          "?days=" +
          this.FilterPiDays,
      };
      await axios(config)
        .then((response) => {
          if (response.status == 200) {
            let PiDataStore = response.data.shops.recordset;
            var uniq = [];
            PiDataStore.map((ele) => uniq.push(ele.ShopCount));
            const ref = JSON.parse(JSON.stringify(PiDataStore));
            let abc = PiDataStore.map((a) => a.ShopCount);
            let def = ref.map((a) => a.chain);
            this.PiGraphData = abc;
            this.RoundGraph = {
              ...this.RoundGraph,
              labels: def,
            };
          }
        })
        .catch(function(error) {
          console.log(error);
        });
      this.$refs.myChart.updateSeries(this.PiGraphData);
      // window.dispatchEvent(new Event('resize'))
    },
    async InvoiceData() {
      // invoice payment data //
      this.DATA_y;
      for (let j = 0; j < 5; j++) {
        this.YearGraphData[j].data = [];
      }
      for (let i = 0; i < this.DATA_y.length; i++) {
        const element = this.DATA_y[i];

        var config = {
          method: "get",
          url:
            "https://engine.netsupport.dk:8270/dashboard/v1/totals/" +
            this.mytoken +
            "?year=" +
            element,
        };
        await axios(config).then((response) => {
          this.InvoicePaymentData = response.data.months.recordset;
          let i = this.YearGraphData.findIndex((a) => a.id == element);
          // this.YearGraphData[i].data = [];
          this.InvoicePaymentData.forEach((ele) => {
            // this.YearGraphData[i].data.push(ele.invoiceAmount);
            let number = Math.trunc(ele.invoiceAmount);
            // console.log("number",number);
            // console.log("1",Math.sign(number)*(Math.abs(number)/1000).toFixed(1) + "k");
            // console.log(Math.sign(number)*((Math.abs(number)/1000).toFixed(1)) + 'k')

            this.YearGraphData[i].data.push(
              Math.sign(number) * (Math.abs(number) / 1000) + "k"
            );

            // this.YearGraphData[i].data.push(ele.invoiceAmount);
          });
        });
      }
      window.dispatchEvent(new Event("resize"));
    },
  },
};
</script>

<style>
.invoice-filter-select-per {
  width: 150px;
}
.piUnderSelectBox {
  max-width: 45%;
}
.piUnderTitle {
  width: 100%;
}
.PiGraphLineText {
  max-width: 34%;
  box-shadow: 0 4px 12px 0 rgb(34 41 47 / 10%);
  background-color: white;
}
.DashBoardMonth {
  max-width: 65%;
  box-shadow: 0 4px 12px 0 rgb(34 41 47 / 10%);
  background-color: white;
}
.LastTenOrd {
  max-width: 39%;
  box-shadow: 0 4px 12px 0 rgb(34 41 47 / 10%);
  background-color: white;
}
.InvoiceGraphLine {
  max-width: 60%;
  box-shadow: 0 4px 12px 0 rgb(34 41 47 / 10%);
  background-color: white;
}
.PIGraphsec1 {
  max-width: 65%;
  box-shadow: 0 4px 12px 0 rgb(34 41 47 / 10%);
  background-image: linear-gradient(341deg, #60d3bd 0%, #fffbfc 95%);
}
.YearsWithMonthsec {
  display: flex;
  justify-content: start;
}
.position-relative {
  font-size: 0.8rem;
}
.PIGraphsec2 {
  max-width: 34%;
  box-shadow: 0 4px 12px 0 rgb(34 41 47 / 10%);
  background-color: white;
}

#SelYearsTitle {
  font-size: 15px;
  color: #ffffff;
  font-weight: 900;
}

.InputBox.col {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

b-row#SelYears {
  margin-top: 2.5%;
}

.Y_W_g {
  padding: 5px;
  border-radius: 7px;
  margin: 0px 10px;
  font-size: 15px;
  font-weight: 900;
}

.Years_wise_graph {
  width: 20px;
  height: 20px;
}

b-row#GraphData {
  max-width: 60% !important;
  box-shadow: 0 4px 12px 0 rgb(34 41 47 / 10%);
  background: linear-gradient(115.3deg, #43cab1 7.4%, #fff 103.1%);
  width: 60%;
}

#TableData {
  background-color: white;
  max-width: 39% !important;
  box-shadow: 0 4px 12px 0 rgb(34 41 47 / 10%);
}

.BtnLike {
  position: absolute;
  bottom: 0px;
  padding: 5px;
}

.MixBox {
  max-width: 24% !important;
  padding: 30px 0px 50px;
  box-shadow: 0 4px 12px 0 rgb(34 41 47 / 10%);
  background-color: white;
}
</style>
<style lang="scss" scoped>
.invoice-filter-select {
  //min-width: 190px;
  min-width: 170px;
  background-color: white;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
